import { useState, useEffect } from 'react';
import { Cookies } from 'react-cookie';
import Api from '../../services/api';
import jwt_decode from "jwt-decode";


import history from '../../history';

const cookies = new Cookies();

export default function useAuth() {
  const [authenticated, setAuthenticated] = useState(false);
  const [errorLogin, setErrorLogin] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = cookies.get('token');
    if (token) {
      const decoded = jwt_decode(token);
      localStorage.setItem("id", decoded.id);
      Api.defaults.headers.Authorization = token;
      (async () => {
        setLoading(true);
        const response = await Api.get('/user').then(function(res){
  
          setAuthenticated(true);
          console.log(authenticated);
          console.log("data é" + res.data);
          console.log( res.data);
        
        }).catch(function(error) {
          console.log(error);
          console.log(authenticated);
          history.push('/');
        });
        setLoading(false);

      })()
    }
    else {
      setLoading(false);
      console.log(authenticated);
    }
    
  }, []);
  
  async function handleLogin(data) {
    const response = await Api.post('/login', data).then(function(res){
      const token = res.data.token;
      console.log(token)
      cookies.set('token', token);
      Api.defaults.headers.Authorization = token;
      setAuthenticated(true);
      history.push('/painel');
    }).catch(function(error) {
      console.log(error.response.data);
      setErrorLogin(error.response.data);
      alert(errorLogin)
    });
  }

  function handleLogout() {
    Api.defaults.headers.Authorization = undefined;
    setAuthenticated(false);
    cookies.remove('token');
    history.push('/');
  }
  
  return { authenticated, loading, handleLogin, handleLogout };
}