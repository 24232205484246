import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { format } from 'date-fns-tz';

import Api from "../../services/api";
import history from '../../history';

import Navbar from "components/Navbar.js";
import Sidebar from "components/Sidebar.js";
import Header from "components/Header.js";
import Footer from "components/Footer.js";
import FormatNumber from 'components/FormatNumber';

const required = "Este campo é obrigatório";
const DATE_FORMATER = require( 'dateformat' );

function NewPack() {
    const [orders, setOrders] = useState([]);
    const [messageRequest, setMessageRequest] = useState("");
    const id = localStorage.getItem('id');
    const date = DATE_FORMATER( new Date(), "yyyy-mm-dd HH:MM:ss" );
    console.log("id é: " + id);
    console.log("a data é: " + date);
    const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      id_user: id
    }
  })
  const [orderId, setOrderId] = useState([]);

  const handleOrderId = (e, value) => {
    if (e.target.checked) {
        setOrderId([...orderId, e.target.value]);
    } else {
        setOrderId(orderId.filter((id) => id !== e.target.value));
    }   
  };

  useEffect(() => {
    if (orderId.length === 0) {
      console.log(orderId)
    } else {
      console.log(orderId)
    }
  }, [orderId]);

  // Error Component
const errorMessage = error => {
    return <div className="invalid-feedback">{error}</div>;
  };

  useEffect(() => {
    (async () => {
      const response = await Api.get('/packnew').then(function(res){
        setOrders(res.data);
        // console.log(orders);
        // console.log(res.data)
      
      }).catch(function(error) {
        history.push('/expire/expire');
        // console.log(error);
        // console.log(error.response)
      });

    })();
  }, []);

  const onSubmit = async data => {
    console.log(data)
  await Api.post("/pack", data).then(function(res){
    console.log(res.data);
    setMessageRequest(<div className='w-full px-4 py-4 mb-5 rounded text-gray-700 font-bold text-center bg-green-200 statusRequest'>Novo pacote cadastrado com sucesso!</div>);
    setTimeout(() => {
      history.push('/pacotes');
    },2000);
  }).catch(function(error) {
    if(error){
      setMessageRequest(<div className='w-full px-4 py-4 mb-5 rounded text-gray-700 font-bold text-center bg-red-200 statusRequest'>Não foi possível cadastrar o pacote. Cód: {error.response.status}</div>);
    }
  });
};

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-gray-200">
        <Navbar />
        {/* Header */}
        <Header />

        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <div className="flex flex-wrap mt-4">
            <div className="w-full mb-12 xl:mb-0 px-4">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                <div className="rounded-t mb-0 px-4 py-3 border-0">
                    {messageRequest}
                  <div className="flex flex-wrap items-center">
                    <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                      <h2 className="font-semibold text-base text-gray-800">
                        Selecione os Pedidos que deseja incluir em seu Pacote
                      </h2>
                    </div>
                    <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                      <Link to="novo-pacote">
                        <button
                          className="bg-yellow-600 text-white hover:bg-yellow-700 text-xs font-bold uppercase px-4 py-3 rounded outline-none focus:outline-none mr-1 mb-1"
                          type="button"
                          style={{ transition: "all .15s ease" }}
                        >
                          Novo Pacote
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="block w-full overflow-x-auto">
                  {/* Projects table */}
                  <table className="items-center w-full bg-transparent border-collapse">
                    <thead>
                      <tr>
                        <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center">
                          
                        </th>
                        <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center">
                          Número
                        </th>
                        <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center">
                          Data
                        </th>
                        <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center">
                          Custo
                        </th>
                        <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center">
                          Estado
                        </th>
                        <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-center">
                          Ações
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                    orders?.map((order, index) => {
                      return (
                        <tr>
                          <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-center">
                            <input type="checkbox" id={order.id} name={order.id} value={order.id} onChange={handleOrderId} key={index} />
                          </th>
                          <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-center">
                             <Link to={"/detalhe-pacote/" + order.id}>{order.id}</Link>
                          </th>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-center">
                            <Link to={"/detalhe-pacote/" + order.id}  key={index}>{format(new Date(order.date), 'dd-MM-yyyy')} às {format(new Date(order.date), 'HH:mm')} hs</Link>
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-center">
                            <Link to={"/detalhe-pacote/" + order.id}  key={index}>{order.price === 0 ? "Em Análise" : FormatNumber(order.price)}</Link>
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-center">
                            <Link to={"/detalhe-pacote/" + order.id}  key={index}>{order.stateorder}</Link>
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-center">
                            <Link to={"/detalhe-pacote/" + order.id}  key={index}>
                                <button className="bg-green-400 rounded px-2 py-1 text-white">
                                    <i class="fas fa-plus-circle text-white mr-2"></i>
                                    Ver Item
                                </button>
                            </Link>
                          </td>
                        </tr>    
                        )
                      })    
                    }
                    </tbody>
                  </table>
                </div>

                <div className="flex-auto px-4 lg:px-10 py-10 pt-6">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-wrap">
                <input
                    type="hidden"
                    name="id_user"
                    defaultValue={id}
                    ref={register()}
                />
                <input
                    type="hidden"
                    name="datepack"
                    defaultValue={date}
                    ref={register()}
                />
                <input
                    type="hidden"
                    name="ids_order"
                    defaultValue={orderId}
                    ref={register()}
                />
              <div className="w-full px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Observação
                  </label>
                  <textarea
                  rpws="3"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="Insira todas as observações sobre o envio do seu pacote"
                    name="description"
                    required
                    ref={register({ required: true })}
                  />
                  <div>
                    {errors.description &&
                    errors.description.type === "required" &&
                    errorMessage(required)}
                  </div>
                </div>
              </div>

              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <button
                    className={orderId.length === 0 ? `bg-gray-200 active:bg-gray-400 text-gray-400 font-bold uppercase text-xs px-12 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150` : `bg-green-500 " + " active:bg-gray-700 text-white font-bold uppercase text-xs px-12 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150`}
                    type="submit"
                    disabled={orderId.length === 0 ? true : false}
                  >
                    Cadastrar
                  </button>
                </div>
              </div>

            </div>
            
          </form>
        </div>


              </div>
            </div>
        </div>

        <Footer />
        </div>
      </div>
    </>
  );
}
export default NewPack;