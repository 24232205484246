import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { format } from 'date-fns-tz';

import Api from "../../services/api";
import history from '../../history';

import Navbar from "components/Navbar.js";
import Sidebar from "components/Sidebar.js";
import Header from "components/Header.js";
import Footer from "components/Footer.js";
import FormatNumber from 'components/FormatNumber';

function Order() {
  const [order, setOrder] = useState();
  const { id } = useParams();
    // console.log(order)

  useEffect(() => {
    (async () => {
      const response = await Api.get('/order/'+id).then(function(res){
        setOrder(res.data);
        
      
      }).catch(function(error) {
        history.push('/expire/expire');
        // console.log(error);
        // console.log(error.response)
      });

    })();
  }, []);

  return (
      order === undefined ? "Carregando" :
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-gray-200">
        <Navbar />
        {/* Header */}
        <Header />

        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <div className="flex flex-wrap mt-4">
            <div className="w-full mb-12 xl:mb-0 px-4">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                <div className="rounded-t mb-0 px-4 py-3 border-0">
                  <div className="flex flex-wrap items-center">
                    <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                      <h2 className="font-semibold text-lg text-gray-800">
                        Detalhes do Pedido
                      </h2>
                    </div>
                    <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                      <Link to="/pedidos">
                        <button
                          className="bg-yellow-600 text-white hover:bg-yellow-700 text-xs font-bold uppercase px-4 py-3 rounded outline-none focus:outline-none mr-1 mb-1"
                          type="button"
                          style={{ transition: "all .15s ease" }}
                        >
                          Voltar aos Pedidos
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="relative w-full px-8 max-w-full flex-grow flex-1 text-left leading-10 mb-4">
                        <strong>Número do Pedido: </strong>{order.id}<br />
                        <strong>Título: </strong>{order.name === null ? "Em Análise" : order.name}<br />
                        <strong>Data: </strong>{format(new Date(order.date), 'dd-MM-yyyy')} às {format(new Date(order.date), 'HH:mm')} hs<br />
                        <strong>Preço: </strong>{order?.price === 0 ? "Em Análise" : FormatNumber(order?.price)}<br />
                        <strong>Estado: </strong>{order.stateorder}<br />
                        <strong>Link: </strong><span className="text-xs">{order.link}</span><br />
                        <strong>Quantidade: </strong>{order.qtd}<br />
                        <strong>Cores: </strong>{order.color}<br />
                        <strong>Tamanhos: </strong>{order.size}<br />
                        <strong>Outras Variações: </strong>{order.variation}<br />
                </div>
                
              </div>
            </div>
        </div>

        <Footer />
        </div>
      </div>
    </>
  );
}
export default Order;