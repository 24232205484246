import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import Api from "../../services/api";
import { Context } from '../../Context/AuthContext';

import Navbar from "components/Navbar.js";
import Sidebar from "components/Sidebar.js";
import Header from "components/Header.js";
import Footer from "components/Footer.js";

import history from '../../history';

function ListUser() {
  const [user, setUser] = useState([]);
  const [farm, setFarm] = useState([]);
  const { id } = useParams();
  const [messageRequest, setMessageRequest] = useState("");

  async function deleteUser() {
    await Api.delete("/user/"+id).then(function(res){
      console.log(res.data);
      setMessageRequest(<div className='w-full px-4 py-4 mb-5 rounded text-gray-700 font-bold text-center bg-green-200 statusRequest'>Cadastro excluido com sucesso!</div>);
      setTimeout(() => {
        history.push('/listar');
      },2000);
    }).catch(function(error) {
      setMessageRequest(<div className='w-full px-4 py-4 mb-5 rounded text-gray-700 font-bold text-center bg-red-200 statusRequest'>Não foi possível excluir o cadastro.</div>);
      console.log(error);
      console.log(error.status);
    });
  };

  useEffect(() => {
    (async () => {
      const response = await Api.get('/user/'+id).then(function(res){
        setUser(res.data);
        console.log(user);
        console.log("id é: "+id)
      
      }).catch(function(error) {
        console.log(error);
      });

      const response2 = await Api.get('/userfarm/'+id).then(function(res){
        setFarm(res.data);
        console.log(user);
        console.log("id é: "+id)
      
      }).catch(function(error) {
        console.log(error);
      });

    })();
  }, []);

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-gray-200">
        <Navbar />
        {/* Header */}
        <Header />

        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            {messageRequest}
            <div className="text-center flex-auto">
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4">
                  <h6 className="text-gray-800 text-left text-xl font-bold">Detalhes do Usuário</h6>
                </div>
                <div className="w-full lg:w-6/12 px-4 text-right">
                  <Link to={"/editar/" + user.id}>
                  <button
                    className="bg-gray-800 active:bg-gray-700 text-white font-bold uppercase text-xs px-12 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                  >
                    Editar
                  </button>
                </Link>
                  <button
                    className="bg-red-400 active:bg-red-500 text-white font-bold uppercase text-xs px-12 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button" onClick={deleteUser}
                  >
                    Deletar
                  </button>
              </div>
              </div>
            </div>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-6">
            <form>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Número
                    </label>
                    <input
                      type="text"
                      readonly="readonly"
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      defaultValue={user.id}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Nome
                    </label>
                    <input
                      type="text"
                      readonly="readonly"
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      defaultValue={user.name}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                    Telefone
                    </label>
                    <input
                      type="text"
                      readonly="readonly"
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      defaultValue={user.phone}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      readonly="readonly"
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      defaultValue={user.email}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      CPF / CNPJ
                    </label>
                    <input
                      type="text"
                      readonly="readonly"
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      defaultValue={user.cpfcnpj}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Inscrição Estadual
                    </label>
                    <input
                      type="text"
                      readonly="readonly"
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      defaultValue={user.state_registration}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      CEP
                    </label>
                    <input
                      type="text"
                      readonly="readonly"
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      defaultValue={user.zip}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-9/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Endereço
                    </label>
                    <input
                      type="text"
                      readonly="readonly"
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      defaultValue={user.address}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Número
                    </label>
                    <input
                      type="text"
                      readonly="readonly"
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      defaultValue={user.number}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-9/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Complemento
                    </label>
                    <input
                      type="text"
                      readonly="readonly"
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      defaultValue={user.address2}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Bairro
                    </label>
                    <input
                      type="text"
                      readonly="readonly"
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      defaultValue={user.district}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Cidade
                    </label>
                    <input
                      type="text"
                      readonly="readonly"
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      defaultValue={user.city}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Estado
                    </label>
                    <input
                      type="text"
                      readonly="readonly"
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      defaultValue={user.state}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      País
                    </label>
                    <input
                      type="text"
                      readonly="readonly"
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      defaultValue={user.country}
                    />
                  </div>
                </div>
              </div>

            </form>
          </div>
          <hr />

          <div className="flex-auto px-4 lg:px-10 pt-6">
            <div className="flex flex-wrap">
              <div className="w-full lg:w-9/12 px-4">
                <h1 className="px-4 text-gray-900 text-lg font-bold">Fazendas</h1>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-right">
                <Link to={"/cadastrarfazenda/" + user.id}>
                  <button
                    className="bg-gray-800 active:bg-gray-700 text-white font-bold uppercase text-xs px-12 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                  >
                    Cadastrar Fazenda
                  </button>
                </Link>
              </div>
            </div>
          </div>

          {
            farm.length === 0 ? 
            <div className="flex-auto px-4 lg:px-10 py-6">
              <h3 className="px-4 text-gray-700 text-md font-bold">Não existem fazendas cadastradas neste usuário.</h3>
            </div> : 
            farm?.map((farm) => {
              
              return (
        
                <div className="flex-auto px-4 lg:px-10 py-10 pt-6">
                  <form>
                    <div className="flex flex-wrap">
                      <div className="w-full lg:w-9/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Nome da Fazenda
                          </label>
                          <input
                            type="text"
                            readonly="readonly"
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            defaultValue={farm.namef}
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-3/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                          Atividade
                          </label>
                          <input
                            type="text"
                            readonly="readonly"
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            defaultValue={farm.activity}
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-3/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                          Bairro / Distrito
                          </label>
                          <input
                            type="text"
                            readonly="readonly"
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            defaultValue={farm.districtf}
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-3/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Email
                          </label>
                          <input
                            type="text"
                            readonly="readonly"
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            defaultValue={farm.cityf}
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-3/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Estado
                          </label>
                          <input
                            type="text"
                            readonly="readonly"
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            defaultValue={farm.statef}
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-3/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            País
                          </label>
                          <input
                            type="text"
                            readonly="readonly"
                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                            defaultValue={farm.countryf}
                          />
                        </div>
                      </div>
                      <div className="w-full lg:w-2/12 pl-4 pr-2">
                        <div className="relative w-full mb-3">
                          <Link to={"/editarfazenda/" + farm.id}>
                            <button className="bg-gray-800 rounded px-2 py-2 text-white w-full">
                              <i class="fas fa-edit text-white mr-2"></i>
                              Editar
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div className="w-full lg:w-2/12 px-2">
                        <div className="relative w-full mb-3">
                          <Link to={"/deletarfazenda/" + farm.id}>
                            <button className="bg-red-400 rounded px-2 py-2 text-white w-full">
                              <i class="fas fa-trash text-white mr-2"></i>
                              Deletar
                            </button>
                          </Link>
                        </div>
                      </div>
                      
                    </div>

                  </form>
                </div>
              )
            })    
          }
        </div>
        <Footer />
      </div>
    </>
  );
}

export default ListUser;
