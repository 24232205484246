import React, { useState, useRef } from 'react';
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import "./style.css";

import Api from "../../services/api";

import history from '../../history';

// Messages
const required = "Este campo é obrigatório";
const maxLength = "Você ultrapassou o limite máximo de caracteres";


// Error Component
const errorMessage = error => {
  return <div className="invalid-feedback">{error}</div>;
};

export default function Create() {
  const [messageRequest, setMessageRequest] = useState("");
  const [expireAcess] = useState("");
  const { register, handleSubmit, errors, watch } = useForm();
  const password = useRef({});
  password.current = watch("password", "");


  const onSubmit = async data => {
    await Api.post("/user", data).then(function (res) {
      console.log(res.data);
      setMessageRequest(<div className='w-full px-4 py-4 mb-5 rounded text-gray-700 font-bold text-center bg-green-200 statusRequest'>Seu perfil foi cadastrado com sucesso! Você será redirecionado para efetuar login.</div>);
      setTimeout(() => {
        history.push('/');
      }, 2000);
    }).catch(function (error) {
      if (error) {
        if (error.response.status === 406) {
          setMessageRequest(<div className='w-full px-4 py-4 mb-5 rounded text-gray-700 font-bold text-center bg-red-200 statusRequest'>Não foi possível efetuar o cadastro. Já existe um usuário com este email.</div>);
        }
        if (error.response.status === 409) {
          setMessageRequest(<div className='w-full px-4 py-4 mb-5 rounded text-gray-700 font-bold text-center bg-red-200 statusRequest'>Não foi possível efetuar o cadastro. Já existe um usuário com este CPF.</div>);
        }
        else {
          setMessageRequest(<div className='w-full px-4 py-4 mb-5 rounded text-gray-700 font-bold text-center bg-red-200 statusRequest'>Não foi possível efetuar o cadastro. Cód: {error.response.status}</div>);
        }
      }
    });
  };
  return (
    <div className="backgroundImage">
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-8/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-1 shadow-lg bg-yellow-600 border-0 bg-opacity-80">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  {
                    expireAcess !== "" ? <div className='text-white text-center bg-red-400 rounded p-2 mb-2'>{expireAcess}</div> : ""
                  }
                  <div className="statusRequest">{messageRequest}</div>
                  <h6 className="text-white text-md font-bold">
                    Faça seu Cadastro
                  </h6>
                </div>
              </div>
              <div className="flex-auto px-4 lg:px-10 py-4 pt-0">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">

                    <div className="relative w-full col-span-2 lg:col-span-1 mb-3">
                      <input
                        type="text"
                        className="px-3 py-3 placeholder-gray-700 text-gray-700 bg-white text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="Nome"
                        name="name"
                        required
                        ref={register({ required: true })}
                      />
                      <div>
                        {errors.name &&
                          errors.name.type === "required" &&
                          errorMessage(required)}
                      </div>
                    </div>

                    <div className="relative w-full col-span-2 mb-3">
                      <input
                        type="email"
                        className="px-3 py-3 placeholder-gray-700 text-gray-700 bg-white text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="Email"
                        name="email"
                        required
                        ref={register({ required: true })}
                      />
                      <div>
                        {errors.email &&
                          errors.email.type === "required" &&
                          errorMessage(required)}
                      </div>
                    </div>

                    <div className="relative w-full mb-3">
                      <input
                        type="text"
                        className="px-3 py-3 placeholder-gray-700 text-gray-700 bg-white text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="Telefone"
                        name="phone"
                        required
                        ref={register({ required: true })}
                      />
                      <div>
                        {errors.phone &&
                          errors.phone.type === "required" &&
                          errorMessage(required)}
                      </div>
                    </div>

                    <div className="relative w-full mb-3">
                      <input
                        type="text"
                        className="px-3 py-3 placeholder-gray-700 text-gray-700 bg-white text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="CPF"
                        name="cpfcnpj"
                        required
                        ref={register({ required: true })}
                      />
                      <div>
                        {errors.cpfcnpj &&
                          errors.cpfcnpj.type === "required" &&
                          errorMessage(required)}
                      </div>
                    </div>

                    <div className="relative w-full col-span-2 lg:col-span-1 mb-3">
                      <input
                        type="text"
                        className="px-3 py-3 placeholder-gray-700 text-gray-700 bg-white text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="CEP"
                        name="zip"
                        required
                        ref={register({ required: true })}
                      />
                      <div>
                        {errors.zip &&
                          errors.zip.type === "required" &&
                          errorMessage(required)}
                      </div>
                    </div>

                    <div className="relative w-full col-span-2 mb-3">
                      <input
                        type="text"
                        className="px-3 py-3 placeholder-gray-700 text-gray-700 bg-white text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="Endereço"
                        name="address"
                        required
                        ref={register({ required: true })}
                      />
                      <div>
                        {errors.address &&
                          errors.address.type === "required" &&
                          errorMessage(required)}
                      </div>
                    </div>

                    <div className="relative w-full mb-3">
                      <input
                        type="text"
                        className="px-3 py-3 placeholder-gray-700 text-gray-700 bg-white text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="Número"
                        name="number"
                        required
                        ref={register({ required: true })}
                      />
                      <div>
                        {errors.number &&
                          errors.number.type === "required" &&
                          errorMessage(required)}
                      </div>
                    </div>

                    <div className="relative w-full lg:col-span-2 mb-3">
                      <input
                        type="text"
                        className="px-3 py-3 placeholder-gray-700 text-gray-700 bg-white text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="Complemento"
                        name="address2"
                        ref={register({ required: true })}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <input
                        type="text"
                        className="px-3 py-3 placeholder-gray-700 text-gray-700 bg-white text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="Bairro"
                        name="district"
                        required
                        ref={register({ required: true })}
                      />
                      <div>
                        {errors.district &&
                          errors.district.type === "required" &&
                          errorMessage(required)}
                      </div>
                    </div>

                    <div className="relative w-full mb-3">
                      <input
                        type="text"
                        className="px-3 py-3 placeholder-gray-700 text-gray-700 bg-white text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="Cidade"
                        name="city"
                        required
                        ref={register({ required: true })}
                      />
                      <div>
                        {errors.city &&
                          errors.city.type === "required" &&
                          errorMessage(required)}
                      </div>
                    </div>

                    <div className="relative w-full mb-3">
                      <input
                        type="text"
                        className="px-3 py-3 placeholder-gray-700 text-gray-700 bg-white text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="Estado"
                        name="state"
                        required
                        ref={register({ required: true })}
                      />
                      <div>
                        {errors.state &&
                          errors.state.type === "required" &&
                          errorMessage(required)}
                      </div>
                    </div>

                    <div className="relative w-full mb-3">
                      <input
                        type="text"
                        className="px-3 py-3 placeholder-gray-700 text-gray-700 bg-white text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="País"
                        name="country"
                        required
                        ref={register({ required: true })}
                      />
                      <div>
                        {errors.country &&
                          errors.country.type === "required" &&
                          errorMessage(required)}
                      </div>
                    </div>

                    <div className="relative w-full mb-3">
                      <input
                        type="password"
                        className="px-3 py-3 placeholder-gray-700 text-gray-700 bg-white text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="Senha"
                        name="password"
                        required
                        ref={register({ required: true, minLength: 6 })}
                      />
                      <div>
                        {errors.password &&
                          errors.password.type === "required" &&
                          errorMessage(required)}
                        {errors.password &&
                          errors.password.type === "maxLength" &&
                          errorMessage(maxLength)}
                      </div>
                    </div>

                    <div className="relative w-full mb-3">
                      <input
                        type="password"
                        className="px-3 py-3 placeholder-gray-700 text-gray-700 bg-white text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="Repita a Senha"
                        name="password_repeat"
                        required
                        ref={register({
                          validate: value =>
                            value === password.current || "A senhas não são iguais!"
                        })}
                      />
                      <div>
                        {errors.password_repeat && <p>{errors.password_repeat.message}</p>}
                      </div>
                    </div>
                    <div className="text-center">
                      <button
                        className="bg-red-800 text-white active:bg-gray-200 text-sm font-bold uppercase px-6 py-3 shadow hover:shadow-lg 
                        outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        type="submit"
                      >
                        Cadastrar
                      </button>
                    </div>

                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}