import React, { useState } from 'react';
import { useForm, Controller } from "react-hook-form";
// import { Link, useParams } from "react-router-dom";
import InputMask from "react-input-mask";

import Api from "../../services/api";

import Navbar from "components/Navbar.js";
import Sidebar from "components/Sidebar.js";
import Header from "components/Header.js";
import Footer from "components/Footer.js";

import history from '../../history';

const required = "Este campo é obrigatório";
const DATE_FORMATER = require( 'dateformat' );
// const maxLength = "Você ultrapassou o limite máximo de caracteres";

// Error Component
const errorMessage = error => {
    return <div className="invalid-feedback">{error}</div>;
  };

function NewOrder() {
  const [messageRequest, setMessageRequest] = useState("");
  const id = localStorage.getItem('id');
  const date = DATE_FORMATER( new Date(), "yyyy-mm-dd HH:MM:ss" );
  // console.log("id é: " + id);
  // console.log("a data é: " + date);
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      id_user: id
    }
  })

  const onSubmit = async data => {
      // console.log(data)
    await Api.post("/order", data).then(function(res){
      console.log(res.data);
      setMessageRequest(<div className='w-full px-4 py-4 mb-5 rounded text-gray-700 font-bold text-center bg-green-200 statusRequest'>Novo pedido cadastrado com sucesso!</div>);
      setTimeout(() => {
        history.push('/pedidos');
      },2000);
    }).catch(function(error) {
      if(error){
        console.log(error)
        history.push('/expire/expire');
        setMessageRequest(<div className='w-full px-4 py-4 mb-5 rounded text-gray-700 font-bold text-center bg-red-200 statusRequest'>Não foi possível efetuar o pedido. Cód: {error.response.status}</div>);
      }
    });
  };

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-gray-200">
        <Navbar />
        {/* Header */}
        <Header />

        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          {messageRequest}
          <div className="text-center flex-auto w-full">
            <h6 className="text-gray-800 text-xl font-bold">Cadastrar Novo Pedido</h6>
            
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-6">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-wrap">
                <input
                    type="hidden"
                    name="id_user"
                    defaultValue={id}
                    ref={register()}
                />
                <input
                    type="hidden"
                    name="date"
                    defaultValue={date}
                    ref={register()}
                />
              <div className="w-full lg:w-8/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Link
                  </label>
                  <input
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="Link"
                    name="link"
                    required
                    ref={register({ required: true })}
                  />
                  <div>
                    {errors.link &&
                    errors.link.type === "required" &&
                    errorMessage(required)}
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                   Quantidade
                  </label>
                  <input
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="Quantidade"
                    name="qtd"
                    required
                    ref={register({ required: true })}
                  />
                  <div>
                    {errors.qtd &&
                    errors.qtd.type === "required" &&
                    errorMessage(required)}
                  </div>
                </div>
              </div>
              <div className="w-full px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Cores
                  </label>
                  <textarea
                    rows="3"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="Coloque cada cor que deseja separado por vírgula"
                    name="color"
                    required
                    ref={register({ required: false })}
                  />
                  <div>
                    {errors.color &&
                    errors.color.type === "required" &&
                    errorMessage(required)}
                  </div>
                </div>
              </div>
              <div className="w-full px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Tamanho
                  </label>
                  <textarea
                    rows="3"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="Coloque cada tamanho que deseja separado por vírgula"
                    name="size"
                    required
                    ref={register({ required: false })}
                  />
                  <div>
                    {errors.size &&
                    errors.size.type === "required" &&
                    errorMessage(required)}
                  </div>
                </div>
              </div>
              <div className="w-full px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Outra Variação
                  </label>
                  <textarea
                  rpws="3"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="Insira todos os detalhes das variações"
                    name="variation"
                    required
                    ref={register({ required: false })}
                  />
                  <div>
                    {errors.variation &&
                    errors.variation.type === "required" &&
                    errorMessage(required)}
                  </div>
                </div>
              </div>

              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <button
                    className="bg-green-500 active:bg-gray-700 text-white font-bold uppercase text-xs px-12 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                    type="submit"
                  >
                    Cadastrar
                  </button>
                </div>
              </div>

            </div>
            
          </form>
        </div>
      </div>
      <Footer />
      </div>
    </>
  );
}

export default NewOrder;
