import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import InputMask from "react-input-mask";

import Api from "../../services/api";
import { Context } from '../../Context/AuthContext';

import Navbar from "components/Navbar.js";
import Sidebar from "components/Sidebar.js";
import Header from "components/Header.js";
import Footer from "components/Footer.js";

const required = "Este campo é obrigatório";
// const maxLength = "Você ultrapassou o limite máximo de caracteres";

// Error Component
const errorMessage = error => {
    return <div className="invalid-feedback">{error}</div>;
  };

function EditUser() {
  const [user, setUser] = useState([]);
  const { id } = useParams();
  const [messageRequest, setMessageRequest] = useState("");
  const { register, handleSubmit, errors, control } = useForm();

  const onSubmit = async data => {
    console.log("o data é: "+data)
    await Api.put("/user", data).then(function(res){
      console.log(res.data);
      setMessageRequest(<div className='w-full px-4 py-4 mb-5 rounded text-gray-700 font-bold text-center bg-green-200 statusRequest'>Cadastro editado com sucesso!</div>);
    }).catch(function(error) {
      if(error){
        setMessageRequest("Não foi possível editar o cadastro.");
        console.log(error);
        console.log(error.status);
      }
    });
  };

  useEffect(() => {
    (async () => {
      const response = await Api.get('/user/'+id).then(function(res){
        setUser(res.data);
        console.log(user)
      
      }).catch(function(error) {
        console.log(error);
      });

    })();
  }, []);

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-gray-200">
        <Navbar />
        {/* Header */}
        <Header />

        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          {messageRequest}
          <div className="text-center flex-auto w-full">
            <h6 className="text-gray-800 text-xl font-bold">Editar Usuário</h6>
            
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-6">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Número
                  </label>
                  <input
                    type="text"
                    readonly="readonly"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    defaultValue={user.id}
                    name="id"
                    ref={register({ required: true })}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Nome
                  </label>
                  <input
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    defaultValue={user.name}
                    placeholder="Nome"
                    name="name"
                    required
                    ref={register({ required: true })}
                  />
                  <div>
                    {errors.name &&
                    errors.name.type === "required" &&
                    errorMessage(required)}
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                   Telefone
                  </label>
                  <input
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    defaultValue={user.phone}
                    placeholder="Telefone"
                    name="phone"
                    required
                    ref={register({ required: true })}
                  />
                  <div>
                    {errors.phone &&
                    errors.phone.type === "required" &&
                    errorMessage(required)}
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    defaultValue={user.email}
                    placeholder="Email"
                    name="email"
                    required
                    ref={register({ required: true })}
                  />
                  <div>
                    {errors.email &&
                    errors.email.type === "required" &&
                    errorMessage(required)}
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    CPF / CNPJ
                  </label>
                  <input
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    defaultValue={user.cpfcnpj}
                    placeholder="CPF / CNPJ"
                    name="cpfcnpj"
                    required
                    ref={register({ required: true })}
                  />
                  <div>
                    {errors.cpfcnpj &&
                    errors.cpfcnpj.type === "required" &&
                    errorMessage(required)}
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Inscrição Estadual
                  </label>
                  <input
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    defaultValue={user.state_registration}
                    placeholder="Inscrição Estadual"
                    name="state_registration"
                    required
                    ref={register({ required: true })}
                  />
                  <div>
                    {errors.state_registration &&
                    errors.state_registration.type === "required" &&
                    errorMessage(required)}
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    CEP
                  </label>
                  <input
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    value={user.zip}
                    placeholder="CEP"
                    name="zip"
                    required
                    ref={register({ required: true })}
                  />
                  <div>
                    {errors.zip &&
                    errors.zip.type === "required" &&
                    errorMessage(required)}
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-9/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Endereço
                  </label>
                  <input
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    defaultValue={user.address}
                    placeholder="Endereço"
                    name="address"
                    required
                    ref={register({ required: true })}
                  />
                  <div>
                    {errors.address &&
                    errors.address.type === "required" &&
                    errorMessage(required)}
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Número
                  </label>
                  <input
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    defaultValue={user.number}
                    placeholder="Número"
                    name="number"
                    required
                    ref={register({ required: true })}
                  />
                  <div>
                    {errors.number &&
                    errors.number.type === "required" &&
                    errorMessage(required)}
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-9/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Complemento
                  </label>
                  <input
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    defaultValue={user.address2}
                    placeholder="Complemento"
                    name="address2"
                    required
                    ref={register({ required: true })}
                  />
                  <div>
                    {errors.address2 &&
                    errors.address2.type === "required" &&
                    errorMessage(required)}
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Bairro
                  </label>
                  <input
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    defaultValue={user.district}
                    placeholder="Bairro"
                    name="district"
                    required
                    ref={register({ required: true })}
                  />
                  <div>
                    {errors.district &&
                    errors.district.type === "required" &&
                    errorMessage(required)}
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Cidade
                  </label>
                  <input
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    defaultValue={user.city}
                    placeholder="Cidade"
                    name="city"
                    required
                    ref={register({ required: true })}
                  />
                  <div>
                    {errors.city &&
                    errors.city.type === "required" &&
                    errorMessage(required)}
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Estado
                  </label>
                  <select name="state"
                  ref={register({ required: false })}
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  >
                    <option value="AC" selected={user.state === "AC" ? "selected":""}>AC</option>
                    <option value="AL" selected={user.state === "AL" ? "selected":""}>AL</option>
                    <option value="AP" selected={user.state === "AP" ? "selected":""}>AP</option>
                    <option value="AM" selected={user.state === "AM" ? "selected":""}>AM</option>
                    <option value="BA" selected={user.state === "BA" ? "selected":""}>BA</option>
                    <option value="CE" selected={user.state === "CE" ? "selected":""}>CE</option>
                    <option value="DF" selected={user.state === "DF" ? "selected":""}>DF</option>
                    <option value="ES" selected={user.state === "ES" ? "selected":""}>ES</option>
                    <option value="GO" selected={user.state === "GO" ? "selected":""}>GO</option>
                    <option value="MA" selected={user.state === "MA" ? "selected":""}>MA</option>
                    <option value="MT" selected={user.state === "MT" ? "selected":""}>MT</option>
                    <option value="MS" selected={user.state === "MS" ? "selected":""}>MS</option>
                    <option value="MG" selected={user.state === "MG" ? "selected":""}>MG</option>
                    <option value="PA" selected={user.state === "PA" ? "selected":""}>PA</option>
                    <option value="PB" selected={user.state === "PB" ? "selected":""}>PB</option>
                    <option value="PR" selected={user.state === "PR" ? "selected":""}>PR</option>
                    <option value="PE" selected={user.state === "PE" ? "selected":""}>PE</option>
                    <option value="PI" selected={user.state === "PI" ? "selected":""}>PI</option>
                    <option value="RJ" selected={user.state === "RJ" ? "selected":""}>RJ</option>
                    <option value="RN" selected={user.state === "RN" ? "selected":""}>RN</option>
                    <option value="RS" selected={user.state === "RS" ? "selected":""}>RS</option>
                    <option value="RO" selected={user.state === "RO" ? "selected":""}>RO</option>
                    <option value="RR" selected={user.state === "RR" ? "selected":""}>RR</option>
                    <option value="SC" selected={user.state === "SC" ? "selected":""}>SC</option>
                    <option value="SP" selected={user.state === "SP" ? "selected":""}>SP</option>
                    <option value="SE" selected={user.state === "SE" ? "selected":""}>SE</option>
                    <option value="TO" selected={user.state === "TO" ? "selected":""}>TO</option>
                  </select>
                </div>
              </div>
              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    País
                  </label>
                  <select name="country"
                  ref={register({ required: false })}
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  >
                    <option value="Brasil" selected="selected">Brasil</option>
                  </select>
                </div>
              </div>
              <div className="w-full lg:w-3/12 px-4">
                <div className="relative w-full mb-3">
                  <button
                    className="bg-green-500 active:bg-gray-700 text-white font-bold uppercase text-xs px-12 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                    type="submit"
                  >
                    Salvar
                  </button>
                </div>
              </div>

            </div>
            
          </form>
        </div>
      </div>
      <Footer />
      </div>
    </>
  );
}

export default EditUser;
